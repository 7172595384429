.action-btn:hover{
background-color: #fe8402 !important;
}

.nav-item a:hover{
  color: #fe8402 !important;
}
.landing{
  height: 100vh;
}
.user svg {
  width: 100px;
  height: 100px;
}